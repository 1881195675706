frappe.provide("frappe");
$.extend(frappe, {
	callq: function(opts) {
		// opts = {"method": "PYTHON MODULE STRING", "args": {}, "callback": function(r) {}}
		if (typeof arguments[0]==='string') {
			opts = {
				method: arguments[0],
				args: arguments[1],
				callback: arguments[2] 
			}
		}

		frappe.prepare_call(opts);
		if(opts.freeze) {
			frappe.freeze();
		}
		return $.ajaxq("frappe",{
			type: opts.type || "POST",
			url: "/",
			data: opts.args,
			dataType: "json",
			headers: { "X-Frappe-CSRF-Token": frappe.csrf_token },
			statusCode: opts.statusCode || {
				404: function() {
					frappe.msgprint(__("Not found"));
				},
				403: function() {
					frappe.msgprint(__("Not permitted"));
				},
				200: function(data) {
					if(opts.callback)
						opts.callback(data);
					if(opts.success)
						opts.success(data);
				}
			}
		}).always(function(data) {
			if(opts.freeze) {
				frappe.unfreeze();
			}

			// executed before statusCode functions
			if(data.responseText) {
				try {
					data = JSON.parse(data.responseText);
				} catch (e) {
					data = {};
				}
			}
			frappe.process_response(opts, data);
		});
	},
	load_option_json: function (field, new_options, selected_option) {
		let $field = $(field);
		if( $field.prop){
			let $options =  $field.prop('options');
		}else{
			let $options =  $field.attr('options');
		}
			
		$('option',  $field).remove();
		$.each(new_options, function(val, text) {
			$options[options.length] = new Option(text, val);
		});
		 $field.val(selected_option);
	},
	load_option_array:function (field, new_options, selected_option) {
		let $field = $(field);
		if( $field.prop){
			let $options =  $field.prop('options');
		}else{
			let $options =  $field.attr('options');
		}
			
		$('option',  $field).remove();
		if($options!=undefined){
			$.each(new_options, function(index, value) {
				$options[options.length] = new Option(value, value);
			});
			$field.val(selected_option);
		}
	}
})

var _val = $.fn.val;
$.fn.val = function (value) {
    var self = $(this);
	var select = self.hasClass("datepicker2");
    if (value === undefined) {
			if(select){
				let o =  _val.apply(this, arguments);
				if(typeof o =="string" && o){
					let o2 = o.split('-');
					return o2[2] + "-" + o2[1] + "-" + o2[0];
				}
				return o;			
			}else{
				return  _val.apply(this, arguments);
			}        
    } else {
        return _val.apply(this, arguments);
    }
} 